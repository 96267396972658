'use client';
import {createUseStyles} from 'react-jss';
import {FC, ReactNode} from 'react';

type PanelVariant = 'info' | 'error' | 'warning';

interface UsePanelStylesProps {
  variant: PanelVariant;
}

const usePanelStyles = createUseStyles(
  theme => ({
    container: ({variant}: UsePanelStylesProps) => ({
      backgroundColor: {
        error: theme.colors.danger.bg,
        warning: theme.colors.warn,
        info: theme.colors.info.bg,
      }[variant],
      color: {
        error: theme.colors.danger.fg,
        warning: theme.colors.text.p,
        info: theme.colors.text.p,
      }[variant],
      boxShadow: theme.shadow.light,
      padding: '1em',
      margin: '1.5em 0',
      display: 'block',
      maxWidth: 'max-content',
    }),
    heading: {
      display: 'block',
    },
  }),
  {name: 'Panel'}
);

export interface PanelProps {
  children: ReactNode;
  id?: string;
  variant: PanelVariant;
  heading?: string;
}

export const Panel: FC<PanelProps> = ({id, variant, heading, children}) => {
  const styles = usePanelStyles({variant});
  return (
    <div id={id} className={`${styles.container} no-print`}>
      {heading && <strong className={styles.heading}>{heading}</strong>}
      {children}
    </div>
  );
};

'use client';
import * as React from 'react';
import {FC} from 'react';
import Link from 'next/link';
import {createUseStyles} from 'react-jss';
import {CssBreakpoints} from 'styles/responsive';
import {ScreenReaderUtils} from 'common/utils/screenreader-utils';

interface ExternalBreadcrumb {
  text: string;
  link: string;
  external: true;
  parent?: ExternalBreadcrumb;
}

export interface Breadcrumb {
  text: string;
  link: string;
  parent?: Breadcrumb | ExternalBreadcrumb;
}

const useBreadcrumbStyles = createUseStyles(
  {
    breadcrumb: {
      display: 'flex',
      padding: '0',
      '& li': {
        display: 'flex',
        listStyle: 'none',
        margin: '1rem 0',
        '@media screen': {
          marginBottom: 0,
        },
        [`@media (max-width: ${CssBreakpoints.XS}px)`]: {
          display: 'none',
          '&:nth-last-child(2)': {
            display: 'inline',
            '&:before': {
              content: '"<"',
              margin: '0 0.5rem 0 0',
            },
          },
        },
      },
      '@media screen': {
        flexFlow: 'wrap',
      },
      '@media print': {
        display: 'none',
      },
    },
    seperator: {
      '&:not(:first-child):before': {
        content: '"|"',
        margin: '0 0.5rem',
      },
    },
    lastLink: {
      fontWeight: 'bold',
    },
  },
  {name: 'Breadcrumbs'}
);

const nestedCrumbsToList = (crumb: Breadcrumb): (Breadcrumb | ExternalBreadcrumb)[] => {
  const crumbList = [];

  let cur: Breadcrumb | ExternalBreadcrumb | undefined = crumb;
  while (cur) {
    crumbList.unshift(cur);
    if ('parent' in cur) {
      cur = cur.parent;
    } else {
      cur = undefined;
    }
  }
  return crumbList;
};

export const Breadcrumbs: FC<{crumbs: Breadcrumb}> = ({crumbs}) => {
  const styles = useBreadcrumbStyles();
  const breadcrumbs: (Omit<Breadcrumb, 'parent'> | ExternalBreadcrumb)[] = nestedCrumbsToList(crumbs);

  return (
    <nav aria-label='breadcrumbs'>
      <ol id='breadcrumb' className={styles.breadcrumb}>
        {breadcrumbs?.map((crumb, i) => {
          if ('external' in crumb) {
            return (
              <li key={crumb.link} className={styles.seperator}>
                <a href={crumb.link}>{crumb.text}</a>
              </li>
            );
          } else if (i === breadcrumbs.length - 1) {
            return (
              <li key={crumb.link} className={styles.seperator}>
                <div className={styles.lastLink} aria-hidden={true}>
                  {crumb.text}
                </div>
                <div className='sr-only'>{ScreenReaderUtils.setScreenreaderText(crumb.text)}</div>
              </li>
            );
          } else {
            return (
              <li key={crumb.link} className={styles.seperator}>
                <Link href={crumb.link}>{crumb.text}</Link>
              </li>
            );
          }
        })}
      </ol>
    </nav>
  );
};
